import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes, { object } from 'prop-types';
import { graphql } from 'gatsby';
import { navigate } from 'gatsby-link'
import Layout from '../components/Layout';
import HeaderMeta from '../components/HeaderMeta';

import {
  ResponsiveMaxWidthFrame,
  SectionGroup,
  TitleBox,
  SubHeadlineBox,
  EmailSubmitForm,
  HeadingBox,
  ResponsiveComparison,
  ResponsiveComparisonText,
  ResponsiveComparisonParagraph,
  LinkBox,
  ComparisonTable,
  FlexColumn,
  ResponsiveImage,
  ResponsiveFigure,
  Button,
  FlexRow,
} from "@minware/ui-minware";
import { track } from '../analytics';

export const ComparisonPageTemplate = ({
  content,
  pathname,
  comfirmDemoPage,
  handleSubmit,
  handleChange,
  handleFocus,
  handleBlur
}) => {
  const headerWithCanon = {
    ...content,
    canonicalUrl: pathname,
    title: content.title,
  };

  const {
    heading,
    subHeading,
    topButtonCopy,
    sections,
    footer,
  } = content;

  return (
    <Layout desktopBgImageName="homeDesktop">
      <HeaderMeta header={headerWithCanon}/>
      <ResponsiveMaxWidthFrame maxWidth="480px" marginBottom={60}>
        <SectionGroup>
          <TitleBox>{heading}</TitleBox>
          {subHeading.map(subHeadString => (
            <SubHeadlineBox centered>
              {subHeadString}
            </SubHeadlineBox>
          ))}
        </SectionGroup>
        <EmailSubmitForm
          buttonLabel={topButtonCopy}
          name="demo"
          method="post"
          action={comfirmDemoPage}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={(e) => handleSubmit(e, 'comparisonTop')}
          onEmailChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          <input type="hidden" name="form-name" value="demo" />
          <input type="hidden" name="location" value="comparison" />
          <input type="hidden" name="page" value={pathname} />
          <div hidden>
            <label>
              Don't fill this out:{' '}
              <input name="bot-field" onChange={handleChange} />
            </label>
          </div>
        </EmailSubmitForm>

        <FlexRow alignItems="center">
          <Button ctaSecondary link="/reports">
            See Live reports
          </Button>
        </FlexRow>

      </ResponsiveMaxWidthFrame>

      {sections.map(({
        heading,
        subHeading,
        link,
        linkText,
        tableWidth,
        comparisonHeading,
        comparisonRows,
        goalMessage,
        showFunnelArrows,
        circleComparisonText,
        figureMaxWidth,
        figureImage,
        figureAspect,
        figureFlex,
      }) => (
        <ResponsiveMaxWidthFrame maxWidth="800px" justifyContent="center">
          <HeadingBox
            tagLevel="h2"
            align="center"
            justifyContent="center"
            forceMargin="40px 0 10px 0"
          >
            {heading}
          </HeadingBox>
          <ResponsiveComparison breakpoint={768}>
            <ResponsiveComparisonText>
              {subHeading.map(subHeadString => (
                <ResponsiveComparisonParagraph>
                  {subHeadString}
                </ResponsiveComparisonParagraph>
              ))}
              {link ? (
                <ResponsiveComparisonParagraph>
                  <LinkBox link={link} inline noFlex size="body">{linkText}</LinkBox>
                </ResponsiveComparisonParagraph>
              ) : null}
            </ResponsiveComparisonText>
            {circleComparisonText ? (
              <ResponsiveImage
                src="/img/competitor-reports.png"
                alt="Competitor report coverage"
                origWidth={640}
                origHeight={640}
                maxWidth={360}
                minWidth={320}
                lrPad={20}
                overlayText={circleComparisonText}
                overlayBox={{
                  top: 174 * 2,
                  left: 115 * 2,
                  width: 90 * 2,
                  height: 90 * 2,
                }}
                fontSize={16}
              />
            ) : figureImage ? (
                <ResponsiveFigure
                  maxWidth={figureMaxWidth}
                  image={figureImage.substr(1) /* hack to avoid treating as image */}
                  aspect={figureAspect}
                  flex={figureFlex}
                  pad={0}
                />
            ) : (
              <ComparisonTable
                heading={comparisonHeading}
                rows={comparisonRows}
                goalMessage={goalMessage}
                showFunnelArrows={showFunnelArrows}
                maxWidth={tableWidth}
              />
            )}
          </ResponsiveComparison>
        </ResponsiveMaxWidthFrame>
      ))}
      <ResponsiveMaxWidthFrame maxWidth="480px" justifyContent="center">
        <SectionGroup>
          <HeadingBox
            tagLevel="h2"
            align="center"
            justifyContent="center"
            forceMargin="20px 0 10px 0"
          >
            {footer.heading}
          </HeadingBox>
          {footer.subHeading.map(subHeadString => (
            <SubHeadlineBox centered>
              {subHeadString}
            </SubHeadlineBox>
          ))}
        </SectionGroup>
        <FlexColumn alignItems="center">
          <EmailSubmitForm
            buttonLabel={footer.buttonCopy}
            name="demo"
            method="post"
            action={comfirmDemoPage}
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={(e) => handleSubmit(e, 'comparisonBottom')}
            onEmailChange={handleChange}
            handleFocus={handleFocus}
            handleBlur={handleBlur}
          >
            {/* The `form-name` hidden field is required to support form submissions without
                JavaScript */}
            <input type="hidden" name="location" value="comparison" />
            <input type="hidden" name="page" value={pathname} />
            <div hidden>
              <label>
                Don't fill this out:{' '}
                <input name="bot-field" onChange={handleChange} />
              </label>
            </div>
          </EmailSubmitForm>

          <FlexRow alignItems="center">
            <Button ctaSecondary link="/reports">
              See Live reports
            </Button>
          </FlexRow>
        </FlexColumn>
      </ResponsiveMaxWidthFrame>

    </Layout>
  )
}

ComparisonPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const ComparisonPage = ({ data, location }) => {
  const { post } = data;
  const { pathname } = location;
  const stripSlashPath = pathname.length > 0 && pathname[pathname.length - 1] === '/'
    ? pathname.substr(0, pathname.length - 1)
    : pathname;
  const content = post.frontmatter;

  const [formState, setFormState] = useState({});

  const timeoutRef = useRef();

  // Track changes to the form state after 500ms of no change
  useEffect(() => {
    if (Object.keys(formState).length) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        track('Comparison Page - Form Change', {
          formState
        })
      }, 500);
    }
  }, [formState]);

  const handleFocus = useCallback((e) => {
    track(`Comparison Page - Form Focus - ${e.target.name}`, {
      value: e.target.value,
    });
  }, []);

  const handleBlur = useCallback((e) => {
    track(`Comparison Page - Form Blur - ${e.target.name}`, {
      value: e.target.value,
    });
  }, []);

  const handleChange = useCallback((e) => {
    const formData = new FormData(e.target.form);
    setFormState(Object.fromEntries(formData.entries()));
  }, []);

  const confirmDemoPage = '/contact/thanks-demo/';

  const handleSubmit = (e) => {
    const formData = new FormData(e.target)
    track('Comparison Page - Form Submit', {
      formState: Object.fromEntries(formData.entries()),
    });
    e.preventDefault()
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => navigate(confirmDemoPage))
      .catch((error) => alert(error))
  }

  return (
    <ComparisonPageTemplate
      content={content}
      post={post}
      pathname={stripSlashPath}
      comfirmDemoPage={confirmDemoPage}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      handleFocus={handleFocus}
      handleBlur={handleBlur}
    />
  )
}

ComparisonPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default ComparisonPage

export const pageQuery = graphql`
  query ComparisonPageByID($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        heading
        subHeading
        topButtonCopy
        sections {
          heading
          subHeading
          link
          linkText
          circleComparisonText
          figureMaxWidth
          figureImage
          figureAspect
          figureFlex
          tableWidth
          comparisonHeading {
            leftHeading
            competitorName
          }
          comparisonRows {
            heading
            leftCheck
            rightCheck
          }
        }
        footer {
          heading
          subHeading
          buttonCopy
        }
      }
    }
  }
`
